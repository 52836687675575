import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Input } from '@paymongo/seeds';

const Form = props => {
  const [url, setUrl] = useState('');

  return (
    <div id="web-form">
      <h1><b>Page</b> inside an iframe</h1>

      <table>
        <tbody>
          <tr>
            <td>URL</td>
            <td>
              <Input
                type="text"
                value={url}
                onChange={e => {setUrl(e.target.value)}}
                wrapperClassName="form-input-wrapper"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <span>The page will be displayed inside an iframe here: </span>

      <pre>
        <iframe src={url} sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation"></iframe>
      </pre>
    </div>
  );
};

export default observer(Form);
