import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useCartStore, useHomeStore } from 'hooks';
import { Image, Notification, NotificationManager } from '@paymongo/seeds';

import ProductCard from './components/ProductCard';

import './FeatureProduct.scss';

const FeaturedProduct = () => {
  const { addToCart, loadCart } = useCartStore();
  const { isLoading, loadProducts, products } = useHomeStore();

  useEffect(() => {
    loadCart();
    loadProducts();
  }, []);

  const onAddToCart = async item => {
    const response = await addToCart(item);

    if (response === 'success') {
      NotificationManager.push('success', 'Item added to cart!', 2500);
    }
  };

  return (
    <div className="featured-product">
      <Notification className="add-cart-notification" position="top right" transition="slide" />
      <div className="featured-product-header">
        <h2>Featured Products</h2>
      </div>

      {isLoading ? (
        <Image alt="Loading..." src="/images/dog-loading.gif" />
      ) : (
        <div className="featured-product-list">
          {products.map(product => {
            return <ProductCard key={product.id} onAddToCart={onAddToCart} product={product} />;
          })}
        </div>
      )}
    </div>
  );
};

export default observer(FeaturedProduct);
