import React from 'react';
import { func, string } from 'prop-types';
import { Label, Input } from '@paymongo/seeds';
import { ErrorMessage, FormItem } from 'components';

import './ExpYearField.scss';

const ExpYearField = ({ error, onBlur, onChange, onFocus, ref, value }) => {
  return (
    <FormItem className="exp-year-container">
      <Label htmlFor="exp-year" required>
        Year
      </Label>
      <Input
        className="exp-year-field"
        id="exp-year"
        maxLength="2"
        minLength="2"
        type="text"
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
      />
      <ErrorMessage message={error} />
    </FormItem>
  );
};

ExpYearField.propTypes = {
  error: string.isRequired,
  onBlur: func.isRequired,
  onChange: func.isRequired,
  onFocus: func.isRequired,
  value: string.isRequired,
};

export default ExpYearField;
