import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useBillingStore, useCartStore, useCheckoutStore, usePaymentStore } from 'hooks';

import './CheckoutScreen.scss';

import { Footer, Header } from 'components';
import BillingDetails from './components/billing_details/BillingDetails';
import Payment from './components/payment/Payment';
import PaymentSteps from './components/payment_steps/PaymentSteps';
import ReferenceNumber from './components/reference_number/ReferenceNumber';
import SelectPaymentMethod from './components/select_payment_method/SelectPaymentMethod';
import Summary from './components/summary/Summary';

const CheckoutScreen = () => {
  const billingStore = useBillingStore();
  const cartStore = useCartStore();
  const paymentStore = usePaymentStore();
  const { billingDetailsForm } = billingStore;
  const { cart, loadCart } = cartStore;
  const store = useCheckoutStore();

  useEffect(() => {
    loadCart();
  }, []);

  return (
    <div className="checkout-screen">
      <Header />
      <ReferenceNumber store={store} />
      <div className="content">
        <PaymentSteps store={store} />
        <div>
          {store.currentStep === 'billing-details' && <BillingDetails store={store} />}
          {store.currentStep === 'payment-details' && (
            <SelectPaymentMethod cart={cart} store={store} />
          )}
          {store.currentStep === 'summary' && (
            <Summary billingDetails={billingDetailsForm.fields} cart={cart} store={store} />
          )}
          {store.currentStep === 'payment' && <Payment paymentStore={paymentStore} store={store} />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default observer(CheckoutScreen);
