const getPaymongoJSUrl = (environment) => {
  let envUrl = process.env.REACT_APP_PAYMONGO_JS_URL_STAGING;

  switch (environment.toLowerCase()) {
    case 'development':
      envUrl = process.env.REACT_APP_PAYMONGO_JS_URL_DEVELOPMENT;
      break;
    case 'uat':
      envUrl = process.env.REACT_APP_PAYMONGO_JS_URL_UAT;
      break;
    case 'production':
      envUrl = process.env.REACT_APP_PAYMONGO_JS_URL_PROD;
    default:
      break;
  }

  return envUrl;
};

export default getPaymongoJSUrl;
