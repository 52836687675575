import React from 'react';
import { func, string } from 'prop-types';
import { Label, Input } from '@paymongo/seeds';
import { ErrorMessage, FormItem } from 'components';

import './AddressLine2Field.scss';

const AddressLine2Field = ({ error, onChange, value }) => {
  return (
    <FormItem>
      <Label htmlFor="address-line2">Line 2</Label>
      <Input
        className="address-line2-field"
        id="address-line2"
        type="text"
        onChange={onChange}
        value={value}
      />
      <ErrorMessage message={error} />
    </FormItem>
  );
};

AddressLine2Field.propTypes = {
  error: string.isRequired,
  onBlur: func.isRequired,
  onChange: func.isRequired,
  value: string.isRequired,
};

export default AddressLine2Field;
