import { createContext } from 'react';
import { observable, action, computed } from 'mobx';
import { CartWebservice } from 'webservices';

const webservice = new CartWebservice();

class CartStore {
  @observable cart = [];

  @observable isSending = false;

  @action setCart = value => {
    this.cart = value;
  };

  @action setIsSending = value => {
    this.isSending = value;
  };

  @action loadCart = async () => {
    this.cart = await webservice.getCart();
    if (!this.cart) {
      this.resetCart();
    }
  };

  @action addToCart = async product => {
    const index = this.cart.findIndex(item => item.id === product.id);

    if (index >= 0) {
      const newValues = {
        itemQty: parseInt(this.cart[index].itemQty) + 1,
        itemTotal: this.cart[index].itemTotal + parseInt(product.price),
      };

      Object.assign(this.cart[index], newValues);
    } else {
      const newItem = Object.assign(product, {
        itemQty: 1,
        itemTotal: parseInt(product.price),
      });

      this.cart.push(newItem);
    }

    this.setIsSending(true);
    const response = await webservice.updateCart(this.cart);
    this.setIsSending(false);
    return response;
  };

  @action removeFromCart = async product => {
    const index = this.cart.findIndex(item => item.id === product.id);

    if (index >= 0) {
      if (parseInt(this.cart[index].itemQty) === 1) {
        this.cart.splice(index, 1);
      } else {
        const newValues = {
          itemQty: parseInt(this.cart[index].itemQty) - 1,
          itemTotal: this.cart[index].itemTotal - parseInt(product.price),
        };

        Object.assign(this.cart[index], newValues);
      }

      const response = await webservice.updateCart(this.cart);
    }
  };

  @action updateCart = async (product, quantity) => {
    const index = this.cart.findIndex(item => item.id === product.id);

    if (index >= 0) {
      if (quantity > 0) {
        const newValues = {
          itemQty: quantity,
          itemTotal: parseInt(product.price) * quantity,
        };

        Object.assign(this.cart[index], newValues);
      } else {
        this.cart.splice(index, 1);
      }

      const response = await webservice.updateCart(this.cart);
    }
  };

  @action resetCart = async () => {
    this.cart = [];

    const response = await webservice.updateCart(this.cart);
  };

  @computed get total() {
    let sum = 0;

    this.cart.forEach(item => {
      sum = sum + item.itemTotal;
    });

    return sum;
  }
}

export default createContext(new CartStore());
