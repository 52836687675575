import React from 'react';
import { observer } from 'mobx-react-lite';
import { shape } from 'prop-types';
import { Card, CardSection } from '@paymongo/seeds';

import './CartItemList.scss';

import CartCheckoutButton from './components/cart-checkout-button/CartCheckoutButton';
import CartItem from './components/cart-item/CartItem';
import CartTotal from './components/cart-total/CartTotal';

const CartItemList = props => {
  const { store } = props;
  const { cart } = store;

  const itemList = !!cart.length ? (
    cart.map((item, index) => {
      return <CartItem key={`cart-item-${index + 1}`} item={item} store={store} />;
    })
  ) : (
    <div>Your cart is empty!</div>
  );

  return (
    <Card className="cart-item-list">
      <CardSection>{itemList}</CardSection>
      {!!cart.length && (
        <CardSection className="cart-total-row">
          <CartTotal cart={cart} />
          <CartCheckoutButton />
        </CardSection>
      )}
    </Card>
  );
};

CartItemList.propTypes = {
  store: shape({}).isRequired,
};

export default observer(CartItemList);
