import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@paymongo/seeds';
import { shape } from 'prop-types';
import { useBillingStore } from 'hooks';

import AddressCityField from './components/address_city_field/AddressCityField';
import AddressCountryField from './components/address_country_field/AddressCountryField';
import AddressLine1Field from './components/address_line1_field/AddressLine1Field';
import AddressLine2Field from './components/address_line2_field/AddressLine2Field';
import AddressPostalCodeField from './components/address_postal_code_field/AddressPostalCodeField';
import AddressStateField from './components/address_state_field/AddressStateField';
import ContactNumberField from './components/contact_number_field/ContactNumberField';
import EmailAddressField from './components/email_address_field/EmailAddressField';
import NameField from './components/name_field/NameField';

import './BillingDetails.scss';

const BillingDetails = props => {
  const { store } = props;

  const { billingDetailsForm, isNextActionEligible } = useBillingStore();
  const {
    addressCity,
    addressCountry,
    addressLine1,
    addressLine2,
    addressPostalCode,
    addressState,
    contactNumber,
    emailAddress,
    name,
  } = billingDetailsForm.fields;

  const {
    error: addressCityError,
    setError: setAddressCityError,
    setValue: setAddressCity,
    value: addressCityValue,
  } = addressCity;
  const {
    error: addressCountryError,
    setError: setAddressCountryError,
    setValue: setAddressCountry,
    value: addressCountryValue,
  } = addressCountry;
  const {
    error: addressLine1Error,
    setError: setAddressLine1Error,
    setValue: setAddressLine1,
    value: addressLine1Value,
  } = addressLine1;
  const {
    error: addressLine2Error,
    setValue: setAddressLine2,
    value: addressLine2Value,
  } = addressLine2;
  const {
    error: addressPostalCodeError,
    setValue: setAddressPostalCode,
    value: addressPostalCodeValue,
  } = addressPostalCode;
  const {
    error: addressStateError,
    setError: setAddressStateError,
    setValue: setAddressState,
    value: addressStateValue,
  } = addressState;
  const {
    error: emailAddressError,
    setError: setEmailAddressError,
    setValue: setEmailAddress,
    value: emailAddressValue,
  } = emailAddress;
  const { error: nameError, setError: setNameError, setValue: setName, value: nameValue } = name;
  const {
    error: contactNumberError,
    setValue: setContactNumber,
    value: contactNumberValue,
  } = contactNumber;

  const previousStepAction = () => {
    const { setCurrentStep, setCurrentStepIndex } = store;

    setCurrentStep('payment-details');
    setCurrentStepIndex(1);
  };

  const nextStepAction = () => {
    const { setCurrentStep, setCurrentStepIndex } = store;

    setCurrentStep('summary');
    setCurrentStepIndex(3);
  };

  const updateAddressCityValue = e => {
    setAddressCity(e.target.value);
  };

  const updateAddressCountryValue = e => {
    setAddressCountry(e.target.value);
  };

  const updateAddressLine1Value = e => {
    setAddressLine1(e.target.value);
  };

  const updateAddressLine2Value = e => {
    setAddressLine2(e.target.value);
  };

  const updateAddressPostalCodeValue = e => {
    setAddressPostalCode(e.target.value);
  };

  const updateAddressStateValue = e => {
    setAddressState(e.target.value);
  };

  const updateContactNumberValue = e => {
    setContactNumber(e.target.value);
  };

  const updateEmailAddressValue = e => {
    setEmailAddress(e.target.value);
  };

  const updateNameValue = e => {
    setName(e.target.value);
  };

  const onBlurAddressLine1 = e => {
    setAddressLine1Error('');

    if (e.target.value === '') {
      setAddressLine1Error('Line 1 is required.');
    }
  };

  const onBlurAddressCity = e => {
    setAddressCityError('');

    if (e.target.value === '') {
      setAddressCityError('City is required.');
    }
  };

  const onBlurAddressCountry = e => {
    setAddressCountryError('');

    if (e.target.value === '') {
      setAddressCountryError('Country is required.');
    }
  };

  const onBlurAddressState = e => {
    setAddressStateError('');

    if (e.target.value === '') {
      setAddressStateError('State/Province is required.');
    }
  };

  const onBlurName = e => {
    setNameError('');

    if (e.target.value === '') {
      setNameError('Name is required.');
    }
  };

  const onBlurEmailAddress = e => {
    setEmailAddressError('');

    if (e.target.value === '') {
      setEmailAddressError('E-mail address is required.');
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        e.target.value
      )
    ) {
      setEmailAddressError('Invalid e-mail address format.');
    }
  };

  return (
    <div className="billing-details-container">
      <div className="billing-details">
        <Fragment>
          <h3>Customer Information</h3>

          <div className="row">
            <div className="left-item">
              <EmailAddressField
                error={emailAddressError}
                onBlur={onBlurEmailAddress}
                onChange={updateEmailAddressValue}
                value={emailAddressValue}
                required
              />
            </div>

            <div className="right-item">
              <ContactNumberField
                error={contactNumberError}
                onChange={updateContactNumberValue}
                value={contactNumberValue}
              />
            </div>
          </div>

          <div className="row">
            <NameField
              error={nameError}
              onBlur={onBlurName}
              onChange={updateNameValue}
              value={nameValue}
            />
          </div>
        </Fragment>

        {store.selectedPaymentMethod === 'card' && (
          <Fragment>
            <h3 className="billing-details-header">Address Information</h3>

            <div className="row">
              <AddressLine1Field
                error={addressLine1Error}
                onBlur={onBlurAddressLine1}
                onChange={updateAddressLine1Value}
                value={addressLine1Value}
              />
            </div>

            <div className="row">
              <AddressLine2Field
                error={addressLine2Error}
                onChange={updateAddressLine2Value}
                value={addressLine2Value}
              />
            </div>

            <div className="row">
              <div className="left-item">
                <AddressCityField
                  error={addressCityError}
                  onBlur={onBlurAddressCity}
                  onChange={updateAddressCityValue}
                  value={addressCityValue}
                />
              </div>

              <div className="right-item">
                <AddressStateField
                  error={addressStateError}
                  onBlur={onBlurAddressState}
                  onChange={updateAddressStateValue}
                  value={addressStateValue}
                />
              </div>
            </div>

            <div className="row">
              <div className="left-item">
                <AddressPostalCodeField
                  error={addressPostalCodeError}
                  onChange={updateAddressPostalCodeValue}
                  value={addressPostalCodeValue}
                />
              </div>

              <div className="right-item">
                <AddressCountryField
                  error={addressCountryError}
                  onBlur={onBlurAddressCountry}
                  onChange={updateAddressCountryValue}
                  value={addressCountryValue}
                />
              </div>
            </div>

            <div className="billing-details-buttons">
              <Button className="back-button" type="default" onClick={previousStepAction}>
                Back
              </Button>
              <Button disabled={isNextActionEligible} onClick={nextStepAction} type="brand">
                Next
              </Button>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

BillingDetails.propTypes = {
  store: shape({}).isRequired,
};

export default observer(BillingDetails);
