import React, { useEffect } from 'react';

const PaymentMethodElements = () => {
  useEffect(() => {
    // TODO: [ENG-25767] Use public key from public key input field
    const paymongo = new window.Paymongo('pk_test_sgRWxJpbxipL69cbdeDeytwZ');
    const elements = paymongo.elements();
    const paymentMethod = elements.create('payment_method');

    paymentMethod.mount('#payment-method')
  });

  return (
    <div id='payment-method-elements'>
      <h1>Paymongojs.elements()</h1>

      <h3>Payment Method Element</h3>
      <div classname='payment-method' id='payment-method'/>
    </div>
  );
};

export default PaymentMethodElements;
