import React from 'react';
import { string } from 'prop-types';

import './ItemName.scss';

const ItemName = props => {
  const { name } = props;
  return <div className="item-name">{name}</div>;
};

ItemName.propTypes = {
  name: string.isRequired,
};

export default ItemName;
