import React from 'react';
import { arrayOf, node, oneOfType, string } from 'prop-types';
import { mergeClasses } from '../../helpers';

import './FormItem.scss';

const FormItem = props => {
  const { children, className } = props;

  const classNames = mergeClasses('form-item', className);

  return <div className={classNames}>{children}</div>;
};

FormItem.propTypes = {
  className: string,
  children: oneOfType([arrayOf(node), node]).isRequired,
};

FormItem.defaultProps = {
  className: '',
};

export default FormItem;
