const isNotEmpty = value => {
  let response = false;

  switch (typeof value) {
    case 'object':
      response = value && Object.keys(value).length > 0;
      break;
    case 'string':
      response = value !== '';
      break;
    default:
      response = value !== undefined;
      break;
  }

  return response;
};

export default isNotEmpty;
