import React from 'react';
import { observer } from 'mobx-react-lite';
import { shape } from 'prop-types';

import './SelectPaymentMethod.scss';

import PaymentMethod from './components/PaymentMethod';

const SelectPaymentMethod = props => {
  const { store, cart } = props;
  const {
    nextStep,
    paymentMethods,
    selectedPaymentMethod,
    setCurrentStep,
    setCurrentStepIndex,
    setSelectedPaymentMethod,
    setSelectedPaymentMethodText,
  } = store;

  const total = !!cart.length ? cart.map(item => item.itemTotal).reduce((a, b) => a + b) : '0.00';

  return (
    <div className="select-payment-method">
      <div className="payment-method-amount">
        <sup>{`\u20B1 `}</sup>
        <span>
          {`${parseInt(total)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
        </span>
        <div className="info">Amount to pay</div>
      </div>
      <div className="payment-method-header">
        <h3>Select Payment Method</h3>
      </div>
      <div className="payment-methods">
        {paymentMethods.map(paymentMethod => {
          return (
            <PaymentMethod
              description={paymentMethod.value}
              disabled={paymentMethod.disabled}
              icon={paymentMethod.icon}
              key={paymentMethod.value}
              method={paymentMethod.text}
              nextStep={nextStep}
              selectedPaymentMethod={selectedPaymentMethod}
              setCurrentStep={setCurrentStep}
              setCurrentStepIndex={setCurrentStepIndex}
              setSelectedPaymentMethod={setSelectedPaymentMethod}
              setSelectedPaymentMethodText={setSelectedPaymentMethodText}
              tabIndex="0"
              tooltip={paymentMethod.tooltip}
              value={paymentMethod.value}
            />
          );
        })}
      </div>
    </div>
  );
};

SelectPaymentMethod.propTypes = {
  store: shape({}).isRequired,
};

export default observer(SelectPaymentMethod);
