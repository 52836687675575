import React from 'react';
import { withRouter } from 'react-router';
import { Button } from '@paymongo/seeds';
import { number, shape } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faShoppingCart } from '@fortawesome/free-solid-svg-icons';

import './Navigation.scss';

const Navigation = props => {
  const { history, totalQty } = props;

  const goToHome = () => {
    history.push('/');
  };

  const goToCart = () => {
    history.push('/cart');
  };

  return (
    <div className="navigation">
      <Button className="home-button" type="link" onClick={goToHome}>
        <FontAwesomeIcon icon={faHome} className="home-icon" />
      </Button>
      <Button className="cart-button" type="link" onClick={goToCart}>
        <FontAwesomeIcon icon={faShoppingCart} className="cart-icon" />
        {totalQty > 0 && <div className="badge">{totalQty}</div>}
      </Button>
    </div>
  );
};

Navigation.propTypes = {
  history: shape({}).isRequired,
  totalQty: number.isRequired,
};

export default withRouter(Navigation);
