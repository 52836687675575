import React from 'react';
import { bool, string } from 'prop-types';

import './ErrorMessage.scss';

const ErrorMessage = props => {
  const { message, disabled, className } = props;

  return message !== '' && !disabled ? (
    <p className={`error-message ${className}`}>{message}</p>
  ) : null;
};

ErrorMessage.propTypes = {
  className: string,
  message: string.isRequired,
  disabled: bool,
};

ErrorMessage.defaultProps = {
  className: '',
  disabled: false,
};

export default ErrorMessage;
