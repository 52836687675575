import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Input, TextArea } from '@paymongo/seeds';

function isValidJson(jsonString) {
  try {
    JSON.parse(jsonString);
  } catch (e) {
    return false;
  }

  return true;
}

function parseResponse(response) {
  if (response instanceof Error) {
    return response.message;
  }

  return JSON.stringify(response, undefined, 2);
}

const JSTesterComponent = ({ callPaymongoJsFunction, functionName, isClientKeyRequired }) => {
  const [payload, setPayload] = useState(null);
  const [payloadWarning, setPayloadWarning] = useState(null);
  const [publicKey, setPublicKey] = useState(undefined);
  const [paymentIntentClientKey, setPaymentIntentClientKey] = useState(null);
  const [response, setResponse] = useState('');

  const onSubmitHandler = () => {
    console.log(`Initializing Paymongo instance with public key ${publicKey}`);

    const paymongo = new window.Paymongo(publicKey);

    const parsedPayload = isValidJson(payload) ? JSON.parse(payload) : payload;

    console.log(`Calling ${functionName} with the following parameters:`);
    console.log('paymentIntentClientKey', paymentIntentClientKey);
    console.log('payload', parsedPayload);

    callPaymongoJsFunction(
      paymongo,
      {
        clientKey: paymentIntentClientKey,
        payload: parsedPayload
      })
      .then(result => {
        setResponse(parseResponse(result));

        console.log('Then block', result);
      })
      .catch(error => {
        setResponse(parseResponse(error));

        console.log('Catch block', error);
      })
      .finally(() => console.log(`End of ${functionName}`));
  };

  useEffect(() => {
    setPayloadWarning(!payload || isValidJson(payload) ? '' : 'Not a valid JSON string.');
    setPaymentIntentClientKey(paymentIntentClientKey || null);
    setPayload(payload || null);
  }, [payload, paymentIntentClientKey, payload]);

  return (
    <div id="js-tester">
      <h1>Paymongojs.{functionName}()</h1>
      <h3>.{functionName}(payload)</h3>

      <h3>Parameters</h3>

      <table>
        <tbody>
          <tr>
            <td width="30%">Public Key</td>
            <td>
              <Input
                type="text"
                value={publicKey}
                onChange={e => setPublicKey(e.target.value)}
                wrapperClassName="tester-input-wrapper"
              />
            </td>
          </tr>
          {isClientKeyRequired && (
            <tr>
              <td width="30%">paymentIntentClientKey</td>
              <td>
                <Input
                  type="text"
                  value={paymentIntentClientKey}
                  onChange={e => setPaymentIntentClientKey(e.target.value)}
                  wrapperClassName="tester-input-wrapper"
                />
              </td>
            </tr>
          )}
          <tr>
            <td width="30%">payload</td>
            <td>
              <TextArea
                name="payload"
                rows="10"
                autoComplete="false"
                rezisable="true"
                onChange={setPayload}
                defaultValue={payload}
              />
              {!!payloadWarning ? <span>{payloadWarning}</span> : null}
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <Button onClick={onSubmitHandler}>
                Submit
              </Button>
            </td>
          </tr>
        </tbody>
      </table>

      <h3>Response</h3>

      <span>
        The response will be printed here but additional information will be logged on the console.
      </span>

      <pre id="js-tester-response">{response}</pre>
    </div>
  );
};

export default observer(JSTesterComponent);
