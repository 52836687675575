import { createContext } from 'react';
import { computed, observable } from 'mobx';
import BillingDetailsForm from './BillingDetailsForm';

class BillingStore {
  @observable billingDetailsForm = new BillingDetailsForm();

  // TODO: Handle other payment methods (GCash, GrabPay and OTC)
  @computed get isNextActionEligible() {
    return (
      this.billingDetailsForm.fields.addressCity.value === '' ||
      this.billingDetailsForm.fields.addressCountry.value === '' ||
      this.billingDetailsForm.fields.addressLine1.value === '' ||
      this.billingDetailsForm.fields.addressState.value === '' ||
      this.billingDetailsForm.fields.emailAddress.value === '' ||
      this.billingDetailsForm.fields.name.value === '' ||
      this.billingDetailsForm.fields.addressCity.error !== '' ||
      this.billingDetailsForm.fields.addressCountry.error !== '' ||
      this.billingDetailsForm.fields.addressLine1.error !== '' ||
      this.billingDetailsForm.fields.addressState.error !== '' ||
      this.billingDetailsForm.fields.emailAddress.error !== '' ||
      this.billingDetailsForm.fields.name.error !== ''
    );
  }
}

export default createContext(new BillingStore());
