import React from 'react';
import { Image } from '@paymongo/seeds';

import './Footer.scss';

const Footer = () => {
  return (
    <div className="footer-container">
      <div></div>
      <div className="footer-image-container">
        <Image className="footer-image" alt="Footer" src="/images/dog-loading.gif" />
      </div>
      <div className="paymongo-image-container">
        <Image className="paymongo-image" alt="Paymongo" src="/images/powered-by-paymongo.png" />
      </div>
    </div>
  );
};

export default Footer;
