import React from 'react';
import { func, string } from 'prop-types';
import { Label, Input } from '@paymongo/seeds';
import { ErrorMessage, FormItem } from 'components';

import './NameField.scss';

const NameField = ({ error, onBlur, onChange, value }) => {
  return (
    <FormItem>
      <Label htmlFor="name" required>
        Name
      </Label>
      <Input
        className="name-field"
        id="name"
        type="text"
        onBlur={onBlur}
        onChange={onChange}
        value={value}
      />
      <ErrorMessage message={error} />
    </FormItem>
  );
};

NameField.propTypes = {
  error: string.isRequired,
  onBlur: func.isRequired,
  onChange: func.isRequired,
  value: string.isRequired,
};

export default NameField;
