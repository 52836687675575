import React, { useEffect } from 'react';

const CardElements = () => {
  useEffect(() => {
    const paymongo = new window.Paymongo('pk_test_3KPtVMLFk49ugg2tGWWmqauQ');
    const elements = paymongo.elements();

    const card = elements.create('card');
    const cardNumber = elements.create('card_number');
    const cardExpiry = elements.create('card_expiry');
    const cardCvc = elements.create('card_cvc');

    card.mount('#card-details')
    cardNumber.mount('#card-number')
    cardExpiry.mount('#card-expiry')
    cardCvc.mount('#card-cvc')
  });
  
  return (
    <div id='card-elements'>
      <h1>Paymongojs.elements()</h1>

      <h3>Card Element</h3>
      <div id='card-details'/>

      <h3>Card Number Element</h3>
      <div className='card' id='card-number'/>
      
      <h3>Card Expiry Element</h3>
      <div className='card' id='card-expiry'/>

      <h3>Card CVC Element</h3>
      <div className='card' id='card-cvc'/>
    </div>
  );
};

export default CardElements;
