import Form from 'stores/lib/form';
import TextField from 'stores/lib/text_field';

export default class CreditCardForm extends Form {
  constructor() {
    super({
      cardNumber: new TextField('cardNumber', ''),
      cvc: new TextField('cvc', ''),
      expMonth: new TextField('expMonth', ''),
      expYear: new TextField('expYear', ''),
    });
  }
}
