import React from 'react';
import { string } from 'prop-types';

import './ItemDescription.scss';

const ItemDescription = props => {
  const { description } = props;
  return <div className="item-description">{description}</div>;
};

ItemDescription.propTypes = {
  description: string.isRequired,
};

export default ItemDescription;
