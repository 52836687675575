import React from 'react';
import { observer } from 'mobx-react-lite';
import { StepItem, Steps } from '@paymongo/seeds';
import { shape } from 'prop-types';

import './PaymentSteps.scss';

const PaymentSteps = props => {
  const { store } = props;
  const { steps } = store;

  const determineStepStatus = stepNumber => {
    if (store.currentStepIndex > stepNumber) {
      return 'completed';
    }

    if (store.currentStepIndex === stepNumber) {
      return 'in_progress';
    }
  };

  return (
    <div className="payment-steps">
      <Steps
        horizontal={true}
        selectedStep={store.currentStepIndex}
        onStepClick={ ()=>{} }
      >
        {steps.map(step => {
          return <StepItem label={step.title} status={determineStepStatus(Number(step.id))}></StepItem>;
        })}
      </Steps>
    </div>
  );
};

PaymentSteps.propTypes = {
  store: shape({}).isRequired,
};

export default observer(PaymentSteps);
