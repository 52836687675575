import React from 'react';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';

import './PaymongojsSandboxScreen.scss';

import getPaymongoJSUrl from './utils/getPaymongoJSUrl';

import { attachPaymentMethod, attachPaymentMethodName } from './js_functions/AttachPaymentMethod';
import {
  cancelPaymentIntent,
  cancelPaymentIntentName,
} from './js_functions/CancelPaymentIntent';
import {
  capturePaymentIntent,
  capturePaymentIntentName,
} from './js_functions/CapturePaymentIntent';
import {
  createPaymentIntent,
  createPaymentIntentName,
} from './js_functions/CreatePaymentIntent';
import {
  createPaymentMethod,
  createPaymentMethodName,
} from './js_functions/CreatePaymentMethod';
import {
  getPaymentIntent,
  getPaymentIntentName,
} from './js_functions/GetPaymentIntent';
import {
  getPaymentMethod,
  getPaymentMethodName,
} from './js_functions/GetPaymentMethod';

import AddressElements from './components/AddressElements';
import CardElements from './components/CardElements';
import Divider from './components/Divider';
import EnvironmentComponent from './components/EnvironmentComponent';
import JSTesterComponent from './components/JSTesterComponent';
import PaymentMethodElements from './components/PaymentMethodElements';

const PaymongojsSandboxScreen = () => {
  let environment = new URLSearchParams(window.location.search).get('environment') ?? 'staging';

  const envUrl = getPaymongoJSUrl(environment);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = envUrl;
    script.async = true;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    }
  }, [envUrl]);

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div className="paymongojs-sandbox-screen">
        <EnvironmentComponent
          environment={environment}
          url={envUrl}
        />
        <Divider />
        <JSTesterComponent
          callPaymongoJsFunction={cancelPaymentIntent}
          functionName={cancelPaymentIntentName}
          isClientKeyRequired={true}
        />
        <JSTesterComponent
          callPaymongoJsFunction={capturePaymentIntent}
          functionName={capturePaymentIntentName}
          isClientKeyRequired={true}
        />
        <JSTesterComponent
          callPaymongoJsFunction={createPaymentIntent}
          functionName={createPaymentIntentName}
          isClientKeyRequired={false}
        />
        <JSTesterComponent
          callPaymongoJsFunction={getPaymentIntent}
          functionName={getPaymentIntentName}
          isClientKeyRequired={true}
        />
        <JSTesterComponent
          callPaymongoJsFunction={createPaymentMethod}
          functionName={createPaymentMethodName}
          isClientKeyRequired={false}
        />
        <JSTesterComponent
          callPaymongoJsFunction={getPaymentMethod}
          functionName={getPaymentMethodName}
          isClientKeyRequired={false}
        />
        <JSTesterComponent
          callPaymongoJsFunction={attachPaymentMethod}
          functionName={attachPaymentMethodName}
          isClientKeyRequired={true}
        />
        <Divider />
        <AddressElements />
        <CardElements />
        <PaymentMethodElements />
      </div>
    </>
  );
};

export default observer(PaymongojsSandboxScreen);
