import React, { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { shape } from 'prop-types';
import { Button, Table, TableBody, TableData, TableRow } from '@paymongo/seeds';

import './Summary.scss';

const Summary = props => {
  const { billingDetails, cart, store } = props;
  const { selectedPaymentMethodText } = store;
  const total = !!cart.length ? cart.map(item => item.itemTotal).reduce((a, b) => a + b) : '0.00';

  const previouseStepAction = () => {
    const { setCurrentStep, setCurrentStepIndex } = store;

    setCurrentStep('billing-details');
    setCurrentStepIndex(2);
  };

  const nextStepAction = () => {
    const { setCurrentStep, setCurrentStepIndex } = store;

    setCurrentStep('payment');
    setCurrentStepIndex(4);
  };

  return (
    <div className="summary-container">
      <div className="summary">
        <Fragment>
          <h3 className="payment-details-header">Payment Details</h3>

          <Table className="summary-table">
            <TableBody className="summary-table-body">
              <TableRow className="summary-table-row">
                <TableData className="summary-table-data-header">Amount</TableData>
                <TableData className="summary-table-data">
                  {`\u20B1 `}
                  {`${parseInt(total)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                </TableData>
              </TableRow>

              <TableRow className="summary-table-row">
                <TableData className="summary-table-data-header">Payment Method</TableData>
                <TableData className="summary-table-data">{selectedPaymentMethodText}</TableData>
              </TableRow>
            </TableBody>
          </Table>
        </Fragment>

        <Fragment>
          <h3 className="billing-details-header">Billing Details</h3>

          <Table className="summary-table">
            <TableBody className="summary-table-body">
              <TableRow className="summary-table-row">
                <TableData className="summary-table-data-header">Name</TableData>
                <TableData className="summary-table-data">{billingDetails.name.value}</TableData>
              </TableRow>

              <TableRow className="summary-table-row">
                <TableData className="summary-table-data-header">E-mail Address</TableData>
                <TableData className="summary-table-data">
                  {billingDetails.emailAddress.value}
                </TableData>
              </TableRow>

              <TableRow className="summary-table-row">
                <TableData className="summary-table-data-header">Contact Number</TableData>
                <TableData className="summary-table-data">
                  {billingDetails.contactNumber.value}
                </TableData>
              </TableRow>

              <TableRow className="summary-table-row">
                <TableData className="summary-table-data-header">Address</TableData>
                <TableData className="summary-table-data">
                  {billingDetails.addressLine1.value}, {billingDetails.addressLine2.value},
                  {billingDetails.addressCity.value}, {billingDetails.addressState.value},
                  {billingDetails.addressPostalCode.value}, {billingDetails.addressCountry.value},
                </TableData>
              </TableRow>
            </TableBody>
          </Table>
        </Fragment>

        <div className="summary-buttons">
          <Button className="back-button" type="default" onClick={previouseStepAction}>
            Back
          </Button>
          <Button type="brand" onClick={nextStepAction}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

Summary.propTypes = {
  store: shape({}).isRequired,
};

export default observer(Summary);
