import { createContext } from 'react';
import { action, observable } from 'mobx';
import CreditCardForm from './CreditCardForm';

class PaymentStore {
  @observable cardNumberCleave = null;

  @observable cvcCleave = null;

  @observable creditCardForm = new CreditCardForm();

  @observable monthCleave = null;

  @observable processing = false;

  @observable yearCleave = null;

  @action setCardNumberCleave = value => {
    this.cardNumberCleave = value;
  };

  @action setCvcCleave = value => {
    this.cvcCleave = value;
  };

  @action setMonthCleave = value => {
    this.monthCleave = value;
  };

  @action setYearCleave = value => {
    this.yearCleave = value;
  };
}

export default createContext(new PaymentStore());
