import React from 'react';
import { func, string } from 'prop-types';
import { Label, Input } from '@paymongo/seeds';
import { ErrorMessage, FormItem } from 'components';

import './ExpMonthField.scss';

const ExpMonthField = ({ error, onBlur, onChange, onFocus, ref, value }) => {
  return (
    <FormItem className="exp-month-container">
      <Label htmlFor="exp-month" required>
        Month
      </Label>
      <Input
        className="exp-month-field"
        id="exp-month"
        maxLength="2"
        minLength="2"
        type="text"
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
      />
      <ErrorMessage message={error} />
    </FormItem>
  );
};

ExpMonthField.propTypes = {
  error: string.isRequired,
  onBlur: func.isRequired,
  onChange: func.isRequired,
  onFocus: func.isRequired,
  value: string.isRequired,
};

export default ExpMonthField;
