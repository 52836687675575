import { createContext } from 'react';
import { observable, action } from 'mobx';
import { ProductWebservice } from 'webservices';

const productWebservice = new ProductWebservice();

class HomeStore {
  @observable isLoading = true;

  @observable products = [];

  @action loadProducts = async () => {
    try {
      this.isLoading = true;
      const response = await productWebservice.get();

      this.setProducts(response.content.products);
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  @action setProducts = value => {
    this.products = value;
  };
}

export default createContext(new HomeStore());
