import React from 'react';
import { func, string } from 'prop-types';
import { Label, Input } from '@paymongo/seeds';
import { ErrorMessage, FormItem } from 'components';

import './CvcField.scss';

const CvcField = ({ error, onBlur, onChange, onFocus, ref, value }) => {
  return (
    <FormItem>
      <Label htmlFor="cvc" required>
        CVC
      </Label>
      <Input
        className="cvc-field"
        id="cvc"
        maxLength="3"
        minLength="3"
        type="text"
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
      />
      <ErrorMessage message={error} />
    </FormItem>
  );
};

CvcField.propTypes = {
  error: string.isRequired,
  onBlur: func.isRequired,
  onChange: func.isRequired,
  onFocus: func.isRequired,
  value: string.isRequired,
};

export default CvcField;
