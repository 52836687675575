import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Card, CardSection, Image } from '@paymongo/seeds';
import { func } from 'prop-types';

import './ProductCard.scss';

const ProductCard = props => {
  const { onAddToCart, product } = props;
  const { description, image: imageFileName, price, title } = product;
  const image = '/images/products/' + imageFileName;

  return (
    <Card className="product-card">
      <CardSection>
        <div className="product-title-container">{title}</div>
        <div className="product-description-container">{description}</div>
      </CardSection>

      <div className="product-image-container">
        <Image className="product-image" alt={title} src={image}></Image>
      </div>

      <CardSection className="product-price-container">
        <span className="product-price">
          {`\u20B1 `}{' '}
          {`${parseInt(price)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
        </span>
      </CardSection>

      <CardSection className="product-cart-container">
        <Button type="brand" onClick={() => onAddToCart(product)}>
          Add to Cart
        </Button>
      </CardSection>
    </Card>
  );
};

ProductCard.propTypes = {
  onAddToCart: func.isRequired,
};

export default observer(ProductCard);
