import React from 'react';
import { func, string } from 'prop-types';
import { Label, Input } from '@paymongo/seeds';
import { ErrorMessage, FormItem } from 'components';

import './CardNumberField.scss';

const CardNumberField = ({ error, onBlur, onChange, onFocus, ref, value }) => {
  return (
    <FormItem>
      <Label htmlFor="card-number" required>
        Card Number
      </Label>
      <Input
        className="card-number-field"
        id="card-number"
        maxLength="16"
        minLength="16"
        type="text"
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
      />
      <ErrorMessage message={error} />
    </FormItem>
  );
};

CardNumberField.propTypes = {
  error: string.isRequired,
  onBlur: func.isRequired,
  onChange: func.isRequired,
  onFocus: func.isRequired,
  value: string.isRequired,
};

export default CardNumberField;
