import React, { useEffect } from 'react';
import { Image } from '@paymongo/seeds';
import { observer } from 'mobx-react-lite';
import { useCartStore } from 'hooks';

import './Header.scss';

import Navigation from './components/navigation/Navigation';

const Header = () => {
  const cartStore = useCartStore();

  useEffect(() => {
    cartStore.loadCart();
  }, []);

  const totalQty = !!cartStore.cart.length
    ? cartStore.cart.map(item => parseInt(item.itemQty)).reduce((a, b) => a + b)
    : 0;

  return (
    <div className="header-container">
      <div className="header-logo">
        <Image alt="The Barkery" src="/images/the-barkery-logo.png" />
      </div>
      <div className="header-navigation">
        <Navigation totalQty={totalQty} />
      </div>
    </div>
  );
};

export default observer(Header);
