import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useCartStore } from 'hooks';
import { Footer, Header } from 'components';

import './CartScreen.scss';

import CartItemList from './components/cart-item-list/CartItemList';

const CartScreen = () => {
  const store = useCartStore();

  useEffect(() => {
    store.loadCart();
  }, []);

  return (
    <div className="cart">
      <Header />
      <div className="shopping-cart-header">
        <h2>Shopping Cart</h2>
      </div>
      <div className="content">
        <CartItemList store={store} />
      </div>
      <Footer />
    </div>
  );
};

export default observer(CartScreen);
