import { observable, action, computed } from 'mobx';
import { isNotEmpty } from '../../../helpers';

class Form {
  @observable fields;

  constructor(fields = {}, payloadMap) {
    this.fields = fields;
    this.payloadMap = payloadMap;
  }

  @action setFormDisabled = value => {
    this.fieldNames.forEach(fieldName => {
      this.fields[fieldName].setDisabled(value);
    });
  };

  @action resetValues = () => {
    this.fieldNames.forEach(fieldName => {
      this.fields[fieldName].resetValue();
    });
  };

  @action resetErrors = () => {
    this.fieldNames.forEach(fieldName => {
      this.fields[fieldName].resetError();
    });
  };

  @action resetForm = () => {
    this.resetValues();
    this.resetErrors();
  };

  @action populateValues(data, resetErrors = true) {
    Object.keys(data).forEach(fieldName => {
      this.fields[fieldName].setValue(data[fieldName]);
      this.fields[fieldName].setDefaultValue(data[fieldName]);
    });

    if (resetErrors) {
      this.resetErrors();
    }
  }

  @computed get fieldNames() {
    return Object.keys(this.fields);
  }

  generatePayloadObject = (attributeList, includeEmptyString = false) => {
    const outputObject = {};

    attributeList.forEach(key => {
      const field = this.fields[this.payloadMap[key]];

      if (isNotEmpty(field.value) || isNotEmpty(field.defaultValue) || includeEmptyString) {
        outputObject[key] = field.value;
      }
    });

    return outputObject;
  };
}

export default Form;
