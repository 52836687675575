import React, { useEffect } from 'react';

const AddressElements = () => {
  useEffect(() => {
    const paymongo = new window.Paymongo('pk_test_3KPtVMLFk49ugg2tGWWmqauQ');

    const elements = paymongo.elements();

    const address = elements.create('address');
    const addressCity = elements.create('address_city');
    const addressCountry = elements.create('address_country');
    const addressField = elements.create('address_field');
    const addressPostalCode = elements.create('address_postal_code');
    const addressState = elements.create('address_state');

    address.mount('#address')
    addressCity.mount('#address-city')
    addressCountry.mount('#address-country')
    addressField.mount('#address-field')
    addressPostalCode.mount('#address-postal-code')
    addressState.mount('#address-state')

  });

  return (
    <div id='address-elements'>
      <h1>Paymongojs.elements()</h1>

      <h3>Address Element</h3>
      <div id='address'/>

      <h3>Address Field Element</h3>
      <div className='address' id='address-field'/>

       <h3>Address City Element</h3>
      <div className='address' id='address-city'/>

      <h3>Address State Element</h3>
      <div className='address' id='address-state'/>

      <h3>Address Postal Code Element</h3>
      <div className='address' id='address-postal-code'/>

      <h3>Address Country Element</h3>
      <div className='address' id='address-country'/>
    </div>
  );
};

export default AddressElements;
