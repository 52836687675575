import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import Cards from 'react-credit-cards';
import Cleave from 'cleave.js';
import { Button } from '@paymongo/seeds';

import CardNumberField from './components/card_number_field/CardNumberField';
import CvcField from './components/cvc_field/CvcField';
import ExpMonthField from './components/exp_month_field/ExpMonthField';
import ExpYearField from './components/exp_year_field/ExpYearField';

import './Payment.scss';
import 'react-credit-cards/es/styles-compiled.css';

const Payment = props => {
  const cardNumberRef = useRef();
  const cvcRef = useRef();
  const monthRef = useRef();
  const yearRef = useRef();

  const { paymentStore, store } = props;
  const {
    creditCardForm,
    setCardNumberCleave,
    setMonthCleave,
    setYearCleave,
  } = paymentStore;
  const { cardNumber, cvc, expMonth, expYear } = creditCardForm.fields;

  const {
    error: cardNumberError,
    setError: setCardNumberError,
    setValue: setCardNumber,
    value: cardNumberValue,
  } = cardNumber;
  const { error: cvcError, setError: setCvcError, setValue: setCvc, value: cvcValue } = cvc;
  const {
    error: expMonthError,
    setError: setExpMonthError,
    setValue: setExpMonth,
    value: expMonthValue,
  } = expMonth;
  const {
    error: expYearError,
    setError: setExpYearError,
    setValue: setExpYear,
    value: expYearValue,
  } = expYear;

  // useEffect(() => {
  //   console.log(cardNumberRef);
  //   setCardNumberCleave(
  //     new Cleave(cardNumberRef.current.input, {
  //       creditCard: true,
  //       onValueChanged: e => {
  //         const { value } = e.target;

  //         setCardNumber(value);
  //       },
  //     })
  //   );

  //   setMonthCleave(
  //     new Cleave(monthRef.current.input, {
  //       date: true,
  //       datePattern: ['m'],
  //       onValueChanged: e => {
  //         const { value } = e.target;

  //         setExpMonth(value);

  //         if (expMonthValue.length === 2) {
  //           yearRef.current.focus();
  //         }
  //       },
  //     })
  //   );

  //   setYearCleave(
  //     new Cleave(yearRef.current.input, {
  //       date: true,
  //       datePattern: ['y'],
  //       onValueChanged: e => {
  //         const { value } = e.target;

  //         setExpYear(value);

  //         if (expYearValue.length === 2) {
  //           cvcRef.current.focus();
  //         }
  //       },
  //     })
  //   );
  // }, []);

  let fieldFocus = null;

  const onFocus = e => {
    fieldFocus = e.target.select();
  };

  const previousStepAction = () => {
    const { setCurrentStep, setCurrentStepIndex } = store;

    setCurrentStep('summary');
    setCurrentStepIndex(3);
  };

  const onClickPay = () => {
    console.log('PAY');
  };

  const updateCardNumberValue = e => {
    if (/^[0-9]{1,16}$/g.test(e.target.value) || e.target.value === '') {
      setCardNumber(e.target.value);
    }
  };

  const updateCvcValue = e => {
    if (/^[0-9]{1,3}$/g.test(e.target.value) || e.target.value === '') {
      setCvc(e.target.value);
    }
  };

  const updateExpMonthValue = e => {
    if (/^[0-9]{1,2}$/g.test(e.target.value) || e.target.value === '') {
      setExpMonth(e.target.value);
    }
  };

  const updateExpYearValue = e => {
    if (/^[0-9]{1,2}$/g.test(e.target.value) || e.target.value === '') {
      setExpYear(e.target.value);
    }
  };

  return (
    <div className="payment-container">
      <div className="payment">
        <div className="row">
          <Cards
            number={cardNumberValue}
            name=" "
            focused={fieldFocus}
            expiry={`${expMonthValue}/${expYearValue}`}
            cvc={cvcValue}
            acceptedCards={['visa', 'mastercard']}
          />

          <div className="right-item">
            <CardNumberField
              error={cardNumberError}
              onBlur={updateCardNumberValue}
              onChange={updateCardNumberValue}
              onFocus={onFocus}
              ref={cardNumberRef}
              value={cardNumberValue}
            />

            <div className="row">
              <ExpMonthField
                error={expMonthError}
                onBlur={updateExpMonthValue}
                onChange={updateExpMonthValue}
                onFocus={onFocus}
                ref={monthRef}
                value={expMonthValue}
              />

              <ExpYearField
                error={expYearError}
                onBlur={updateExpYearValue}
                onChange={updateExpYearValue}
                onFocus={onFocus}
                ref={yearRef}
                value={expYearValue}
              />

              <CvcField
                error={cvcError}
                onBlur={updateCvcValue}
                onChange={updateCvcValue}
                onFocus={onFocus}
                ref={cvcRef}
                value={cvcValue}
              />
            </div>
          </div>
        </div>
        <div className="payment-buttons">
          <Button className="back-button" type="default" onClick={previousStepAction}>
            Back
          </Button>
          <Button type="brand" onClick={onClickPay}>
            Pay
          </Button>
        </div>
      </div>
    </div>
  );
};

export default observer(Payment);
