import React from 'react';
import { string } from 'prop-types';
import { Image } from '@paymongo/seeds';

import './ItemImage.scss';

const ItemImage = props => {
  const { image, name } = props;
  const imageUrl = `images/products/${image}`;

  return <Image className="item-image" alt={name} src={imageUrl} />;
};

ItemImage.propTypes = {
  image: string.isRequired,
  name: string.isRequired,
};

export default ItemImage;
