import React from 'react';
import { func, string } from 'prop-types';
import { Label, Input } from '@paymongo/seeds';
import { ErrorMessage, FormItem } from 'components';

import './AddressStateField.scss';

const AddressStateField = ({ error, onBlur, onChange, value }) => {
  return (
    <FormItem>
      <Label htmlFor="address-state" required>
        State/Province
      </Label>
      <Input
        className="address-state-field"
        id="address-state"
        type="text"
        onBlur={onBlur}
        onChange={onChange}
        value={value}
      />
      <ErrorMessage message={error} />
    </FormItem>
  );
};

AddressStateField.propTypes = {
  error: string.isRequired,
  onBlur: func.isRequired,
  onChange: func.isRequired,
  value: string.isRequired,
};

export default AddressStateField;
