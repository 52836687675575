import React from 'react';
import { func, string } from 'prop-types';
import { Label, Input } from '@paymongo/seeds';
import { ErrorMessage, FormItem } from 'components';

import './AddressCityField.scss';

const AddressCityField = ({ error, onBlur, onChange, value }) => {
  return (
    <FormItem>
      <Label htmlFor="address-city" required>
        City
      </Label>
      <Input
        className="address-city-field"
        type="text"
        onBlur={onBlur}
        onChange={onChange}
        value={value}
      />
      <ErrorMessage message={error} />
    </FormItem>
  );
};

AddressCityField.propTypes = {
  error: string.isRequired,
  onBlur: func.isRequired,
  onChange: func.isRequired,
  value: string.isRequired,
};

export default AddressCityField;
