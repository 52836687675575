import { observable, action } from 'mobx';

export default class FormField {
  @observable name;

  @observable value = '';

  @observable defaultValue;

  @observable disabled = false;

  @observable error = '';

  constructor(name, value) {
    this.name = name;
    this.value = value;
    this.defaultValue = value;
  }

  @action setName = value => {
    this.name = value;
  };

  @action setValue = value => {
    this.value = value;
  };

  @action setDefaultValue = value => {
    this.defaultValue = value;
  };

  @action resetValue = () => {
    this.value = this.defaultValue;
  };

  @action resetError = () => {
    this.error = '';
  };

  @action resetField = () => {
    this.resetValue();
    this.resetError();
  };

  @action setDisabled = value => {
    this.disabled = value;
  };

  @action setError = value => {
    this.error = value;
  };
}
