import { createContext } from 'react';
import { observable, action } from 'mobx';

class CheckoutStore {
  @observable currentStep = 'payment-details';

  @observable currentStepIndex = 1;

  @observable isLoading = true;

  @observable nextStep = '';

  @observable paymentMethods = [
    {
      disabled: false,
      icon: '/images/paymentMethods/card.png',
      text: 'Credit/Debit Card',
      tooltip: '',
      value: 'card',
    },
    {
      disabled: true,
      icon: '/images/paymentMethods/gcash.png',
      text: 'GCash',
      tooltip: 'This payment method is currently unavailable.',
      value: 'gcash',
    },
    {
      disabled: true,
      icon: '/images/paymentMethods/grab-pay.png',
      text: 'GrabPay',
      tooltip: 'This payment method is currently unavailable.',
      value: 'grab_pay',
    },
    {
      disabled: true,
      icon: '/images/paymentMethods/otc.png',
      text: 'OTC or coins.ph',
      tooltip: 'This payment method is currently unavailable.',
      value: 'otc',
    },
  ];

  @observable referenceNumber = Math.random().toString(36).substring(2, 8);

  @observable selectedPaymentMethod = '';

  @observable selectedPaymentMethodText = '';

  @observable steps = [
    {
      id: '1',
      title: 'Payment Details',
      value: 'payment-details',
    },
    {
      id: '2',
      title: 'Billing Details',
      value: 'billing-details',
    },
    {
      id: '3',
      title: 'Summary',
      value: 'summary',
    },
    {
      id: '4',
      title: 'Payment',
      value: 'payment',
    },
  ];

  @action setCurrentStep = value => {
    this.currentStep = value;
  };

  @action setCurrentStepIndex = value => {
    this.currentStepIndex = value;
  };

  @action setSelectedPaymentMethod = value => {
    this.selectedPaymentMethod = value;
  };

  @action setSelectedPaymentMethodText = value => {
    this.selectedPaymentMethodText = value;
  };
}

export default createContext(new CheckoutStore());
