import Form from 'stores/lib/form';
import TextField from 'stores/lib/text_field';

export default class BillingDetailsForm extends Form {
  constructor() {
    super({
      addressCity: new TextField('addressCity', ''),
      addressCountry: new TextField('addressCountry', ''),
      addressLine1: new TextField('addressLine1', ''),
      addressLine2: new TextField('addressLine2', ''),
      addressPostalCode: new TextField('addressPostalCode', ''),
      addressState: new TextField('addressState', ''),
      contactNumber: new TextField('contactNumber', ''),
      emailAddress: new TextField('emailAddress', ''),
      name: new TextField('name', ''),
    });
  }
}
