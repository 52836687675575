import React from 'react';
import { func, string } from 'prop-types';
import { Label, Input } from '@paymongo/seeds';
import { ErrorMessage, FormItem } from 'components';

import './ContactNumberField.scss';

const ContactNumberField = ({ error, onChange, value }) => {
  return (
    <FormItem>
      <Label htmlFor="contact-number">Contact Number</Label>
      <Input
        className="contact-number-field"
        id="contact-number"
        type="text"
        onChange={onChange}
        value={value}
      />
      <ErrorMessage message={error} />
    </FormItem>
  );
};

ContactNumberField.propTypes = {
  error: string.isRequired,
  onBlur: func.isRequired,
  onChange: func.isRequired,
  value: string.isRequired,
};

export default ContactNumberField;
