import React from 'react';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';

import './Iframe.scss';

import Form from './components/Form';

const Iframe = () => {
  return (
      <>
        <Helmet>
          <link href="https://fonts.googleapis.com/css2?family=Inconsolata:wght@300&display=swap" rel="stylesheet"/>
        </Helmet>
        <div className="iframe-screen">
          <Form></Form>
        </div>
      </>
  );
};

export default observer(Iframe);
