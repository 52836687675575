import React from 'react';
import { observer } from 'mobx-react-lite';

import './HomeScreen.scss';

import { Footer, Header } from 'components';
import FeaturedProduct from './components/FeaturedProduct';

const HomeScreen = () => {
  return (
    <div className="home-screen">
      <Header />
      <FeaturedProduct />
      <Footer />
    </div>
  );
};

export default observer(HomeScreen);
