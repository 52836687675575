import React from 'react';
import { observer } from 'mobx-react-lite';
import { shape } from 'prop-types';

import './ReferenceNumber.scss';

const ReferenceNumber = props => {
  const { store } = props;
  const { referenceNumber } = store;

  return (
    <div className="reference-number">
      <span className="label">Reference Number</span>
      <span className="reference-number-data">{referenceNumber}</span>
    </div>
  );
};

ReferenceNumber.propTypes = {
  store: shape({}).isRequired,
};

export default observer(ReferenceNumber);
