export default class CartWebservice {
  async getCart() {
    const delay = () => new Promise(resolve => setTimeout(resolve, 500));

    await delay();
    return JSON.parse(localStorage.getItem('cart'));
  }

  async updateCart(payload) {
    window.localStorage.setItem('cart', JSON.stringify(payload));
    const delay = () => new Promise(resolve => setTimeout(resolve, 500));

    await delay();
    return 'success';
  }
}
