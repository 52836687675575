import React from 'react';
import { observer } from 'mobx-react-lite';
import { shape } from 'prop-types';

import './CartItem.scss';

import ItemDescription from './components/item-description/ItemDescription';
import ItemImage from './components/item-image/ItemImage';
import ItemName from './components/item-name/ItemName';
import ItemPrice from './components/item-price/ItemPrice';
import ItemQuantity from './components/item-quantity/ItemQuantity';

const CartItem = props => {
  const { item, store } = props;
  const { description, image, price, title } = item;

  return (
    <div className="cart-item">
      <ItemImage image={image} name={title} />
      <div className="name-column">
        <ItemName name={title} />
        <ItemDescription description={description} />
      </div>
      <ItemPrice price={price} />
      <ItemQuantity item={item} store={store} />
    </div>
  );
};

CartItem.propTypes = {
  item: shape({}).isRequired,
  store: shape({}).isRequired,
};

export default observer(CartItem);
