import React from 'react';
import { observer } from 'mobx-react-lite';
import { shape } from 'prop-types';
import { Input } from '@paymongo/seeds';

import './ItemQuantity.scss';

const ItemQuantity = props => {
  const { item, store } = props;
  const { itemQty } = item;

  const onChange = e => {
    store.updateCart(item, e.target.value);
  };

  return (
    <div className="item-quantity-container">
      <Input
        className="item-quantity"
        type="number"
        value={itemQty}
        onChange={onChange}
      />
    </div>
  );
};

ItemQuantity.propTypes = {
  item: shape({}).isRequired,
  store: shape({}).isRequired,
};

export default observer(ItemQuantity);
