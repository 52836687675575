import React from 'react';
import { observer } from 'mobx-react-lite';
import { arrayOf, shape } from 'prop-types';

import './CartTotal.scss';

const CartTotal = props => {
  const { cart } = props;

  const total = !!cart.length ? cart.map(item => item.itemTotal).reduce((a, b) => a + b) : '0.00';

  return (
    <div className="cart-total">
      <span className="total-label">Total:</span>
      <span className="total-value">
        <span className="php-symbol">{`\u20B1 `}</span>
        {`${parseInt(total)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
      </span>
    </div>
  );
};

CartTotal.propTypes = {
  cart: arrayOf(shape({})).isRequired,
};

export default observer(CartTotal);
