import React from 'react';
import { string } from 'prop-types';

import './ItemPrice.scss';

const ItemPrice = props => {
  const { price } = props;

  return (
    <div className="item-price">
      <span className="php-symbol">{`\u20B1 `} </span>
      {price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
    </div>
  );
};

ItemPrice.propTypes = {
  price: string.isRequired,
};

export default ItemPrice;
