import React from 'react';
import { Select, SelectOption, Label } from '@paymongo/seeds';

import '../PaymongojsSandboxScreen.scss';

const environments = ['development', 'staging', 'uat', 'production'];

const EnvironmentComponent = ({ environment, url }) => {
  const onChange = selectedEnv => {
    const query = new URLSearchParams(window.location.search);
    query.set("environment", selectedEnv);
    window.location.href = window.location.pathname + "?" + query.toString();
  };

  return (
    <div className='env-select'>
        <h1>Environment: </h1>
        <Select 
          name="environment"
          placeholder={environment}
          value={environment}
          onChange={onChange}>
            {environments.map(env => (
              <SelectOption
                key={env}
                value={env}
              >
                {env}
              </SelectOption>
            ))} 
        </Select>
        <h1>JS URL: {url} </h1>
    </div>
  );
};

export default EnvironmentComponent;
