import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './scss/base.scss';

import CartScreen from './screens/cart/CartScreen';
import HomeScreen from './screens/home/HomeScreen';
import CheckoutScreen from './screens/checkout/CheckoutScreen';
import Iframe from './screens/iframe/Iframe';
import PaymongojsSandboxScreen from './screens/sandbox/PaymongojsSandboxScreen';

const App = () => {
  return (
    <Router>
      <Helmet>
        <title>The Barkery PH</title>
      </Helmet>
      <Switch>
        <Route exact path="/" component={HomeScreen} />
        <Route exact path="/cart" component={CartScreen} />
        <Route exact path="/checkout" component={CheckoutScreen} />
        <Route exact path="/iframe" component={Iframe} />
        <Route exact path="/paymongojs-sandbox" component={PaymongojsSandboxScreen} />
      </Switch>
    </Router>
  );
};

export default App;
