import React from 'react';
import { observer } from 'mobx-react-lite';
import { Card, Image, Tooltip } from '@paymongo/seeds';

import './PaymentMethod.scss';

const PaymentMethod = props => {
  const { disabled, icon, method, selectedPaymentMethod, tooltip, value, tabIndex } = props;

  const nextStepAction = () => {
    const {
      setCurrentStep,
      setCurrentStepIndex,
      setSelectedPaymentMethod,
      setSelectedPaymentMethodText,
      value,
    } = props;

    setCurrentStep('billing-details');
    setCurrentStepIndex(2);
    setSelectedPaymentMethod(value);
    setSelectedPaymentMethodText(method);
  };

  return (
    <Tooltip content={disabled ? tooltip : ''} position="right">
      <div
        className={`payment-method-card-container ${disabled ? 'disabled' : ''}`}
        onClick={disabled ? null : () => nextStepAction()}
        tabIndex={tabIndex}
      >
        <Card
          className={`payment-method-card ${selectedPaymentMethod === value ? ' active' : ''}`}
          key={value}
        >
          <span className={`checkmark${selectedPaymentMethod === value ? '' : ' hidden'}`}>
            <div className="checkmark-circle" />
            <div className="checkmark-stem" />
            <div className="checkmark-kick" />
          </span>

          <div className="payment-method-image">
            <Image alt={value} src={icon} />
          </div>

          <div className="payment-method-name">
            <span>{method}</span>
          </div>
        </Card>
      </div>
    </Tooltip>
  );
};

export default observer(PaymentMethod);
