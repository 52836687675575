import React from 'react';
import { Button } from '@paymongo/seeds';

import './CartCheckoutButton.scss';

const CartCheckoutButton = () => {
  const onClick = () => {
    window.location.href = '/checkout';
  };

  return (
    <Button type="brand" onClick={onClick}>
      Proceed to checkout
    </Button>
  );
};

export default CartCheckoutButton;
